












import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
    data() {
        const that = this;
        return {
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "name",
                    "sort-desc": false,
                },
                content: {
                    name: "Licenses",
                    urlEnabled: true,
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    filters: {
                        filters: {
                            tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Organization",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("tenants", "findAll", {
                                                    payload: JSON.stringify({
                                                        limit,
                                                        search,
                                                        orderBy: "name",
                                                        orderType: "asc",
                                                        filters: [
                                                            {
                                                                key: "_id",
                                                                operator: "equal_to",
                                                                value,
                                                            },
                                                        ],
                                                    }),
                                                });
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                        },
                    },
                    displayFields: {
                        tenant: userManager.checkRole(["systemAdmin"]) && {
                            text: "Organization",
                            options: {
                                subProp: "tenant.name",
                                label: true,
                                sortBy: "tenantId",
                            },
                        },
                        description: {
                            text: "Description",
                        },
                        expDate: {
                            text: "Expiration Date",
                            options: {
                                transform: (data) => {
                                    return new Date(data).toLocaleDateString("en-GB");
                                },
                            },
                        },
                        createdTime: {
                            text: "Created Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        updatedTime: {
                            text: "Updated Time",
                            sortable: true,
                            options: {
                                filter: "dateTime",
                            },
                        },
                        action: {
                            text: "Actions",
                        },
                    },
                    topActionButtons: {
                        insert: userManager.checkRole(["systemAdmin"]) && {},
                    },
                    template: {
                        itemActionButtons: {
                            edit: userManager.checkRole(["systemAdmin"]) && {
                                target: {
                                    dialog: {
                                        ext: {
                                            subTitleMaker: "tenant.name",
                                        },
                                    },
                                },
                            },
                            delete: userManager.checkRole(["systemAdmin"]) && {},
                        },
                    },
                    findAllOptionsFilter(options) {
                        return options;
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll(options) {
                            const { items, count } = await coreApiClient.call(
                                "licenses",
                                "findAll",
                                options
                            );
                            return [items, count];
                        },
                        async insert(item) {
                            const fileBase64 = await that.createImage(item.image);
                            return await coreApiClient.call(
                                "licenses",
                                "create",
                                undefined,
                                { ...item, imageBase64: fileBase64 }
                            );
                        },
                        async update(item) {
                            const fileBase64 = await that.createImage(item.image);
                            return await coreApiClient.call(
                                "licenses",
                                "update",
                                {
                                    id: item._id,
                                },
                                {
                                    data: { ...item, imageBase64: fileBase64 },
                                }
                            );
                        },
                        async delete(item) {
                            return await coreApiClient.call("licenses", "delete", {
                                id: item._id,
                            });
                        },
                    },
                    default: {
                        defaultInputs: {
                            tenantId: userManager.checkRole(["systemAdmin"]) && {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Organization",
                                    required: false,
                                    "item-value": "_id",
                                    "item-text": (item) => `${item.name}`,
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("tenants", "findAll", {
                                                    payload: JSON.stringify({
                                                        limit,
                                                        search,
                                                        orderBy: "name",
                                                        orderType: "asc",
                                                    }),
                                                });
                                                return items;
                                            },
                                        },
                                    },
                                },
                            },
                            description: {
                                type: "textLarge",
                                attrs: {
                                    label: "Description",
                                },
                            },
                            expDate: {
                                type: "XDateTimePicker",
                                attrs: {
                                    label: "Expiration Date",
                                    required: true,
                                },
                            },
                        },
                        insertForm: userManager.checkRole(["systemAdmin"]) && {
                            content: {
                                fieldNames: ["tenantId", "description", "expDate"],
                            },
                        },
                        editForm: userManager.checkRole(["systemAdmin"]) && {
                            content: {
                                fieldNames: ["description", "expDate"],
                            },
                        },
                        delete: {
                            confirmDisplayField: "tenantId",
                        },
                    },
                },
            },
        };
    },
    methods: {
        createImage(file) {
            return new Promise((resolve, reject) => {
                if (file && typeof file !== "string") {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(null);
                }
            });
        },
    },
});

